import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Alert
} from '@mui/material';
import axios from 'axios';
import { 
    getAuth, 
    EmailAuthProvider, 
    reauthenticateWithCredential 
} from 'firebase/auth'; // Import required Firebase methods

const EmployeeStatus = ({ setSuccessMessage, setError }) => {
    const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
    const [employeePassword, setEmployeePassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSetEmployeeClaim = async () => {
        setIsLoading(true);
        const auth = getAuth();
        try {
            const userEmail = auth.currentUser.email;
    
            if (!userEmail) {
                throw new Error('Email is missing from the user profile.');
            }
    
            // Reauthenticate user with provided password and email
            const credential = EmailAuthProvider.credential(userEmail, employeePassword);
            await reauthenticateWithCredential(auth.currentUser, credential);
    
            // Get the user's ID token
            const idToken = await auth.currentUser.getIdToken();
    
            // Call the isEmployee endpoint to set the claim
            const response = await axios.get('https://api.fivespiceindiangrocery.com/api/isEmployee', {
                params: { phoneNumber: auth.currentUser.phoneNumber }, // Pass the phone number as a query param
                headers: {
                    Authorization: idToken, // Send the ID token in headers
                },
            });
    
            if (response.data.isEmployee) {
                await auth.currentUser.getIdToken(true); // Refresh token to reflect new claims
                setSuccessMessage('Employee claim set successfully!');
            } else {
                setError('Failed to verify employee status.');
            }
        } catch (err) {
            setError(err.message || 'Failed to set employee claim.');
        } finally {
            setIsLoading(false);
            setIsEmployeeModalOpen(false);
        }
    };
    
    

    return (
        <Box>
            <Typography variant="h6" color="primary" gutterBottom>
                Employee Status
            </Typography>
            <Button
                variant="outlined"
                onClick={() => setIsEmployeeModalOpen(true)}
                fullWidth
            >
                Set Employee Claim
            </Button>

            <Dialog
                open={isEmployeeModalOpen}
                onClose={() => !isLoading && setIsEmployeeModalOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Verify Password</DialogTitle>
                <DialogContent>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Enter your password to set your account as an employee.
                    </Alert>
                    <TextField
                        label="Password"
                        type="password"
                        value={employeePassword}
                        onChange={(e) => setEmployeePassword(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsEmployeeModalOpen(false)}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSetEmployeeClaim}
                        variant="contained"
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EmployeeStatus;
