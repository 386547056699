import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Avatar,
  CircularProgress,
  Alert,
  List,
  Divider,
  Chip,
  Link,
  Stack,
} from '@mui/material';
import {Pending as PendingIcon} from '@mui/icons-material'
import { selectAllInvoices } from '../../redux/invoiceSlice'; // Adjust the path
import { useAuth } from '../../context/AuthContext'; // Custom hook for authentication
import moment from 'moment';

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { displayName = 'Guest', photoURL = '' } = currentUser || {};

  // Fetching invoices from Redux
  const invoices = useSelector(selectAllInvoices);

  const status = useSelector((state) => state.invoices.status);
  const error = useSelector((state) => state.invoices.error);
  const [currentTime, setCurrentTime] = useState(moment().format('dddd, MMMM Do YYYY, h:mm:ss A'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('dddd, MMMM Do YYYY, h:mm:ss A'));
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Calculate Metrics
  const totalRevenue = invoices.reduce((sum, invoice) => sum + (invoice.invoiceTotal || 0), 0);
  const pendingInvoices = invoices.filter((invoice) => invoice.invoiceStatus === 'pending').length;
  const newInvoices = invoices.filter((invoice) => invoice.invoiceStatus === 'new').length;
  const completedInvoices = invoices.filter((invoice) => invoice.invoiceStatus === 'done').length;

  // Get the latest 5 'new' invoices sorted by date
  const latestNewInvoices = invoices
    .filter((invoice) => invoice.invoiceStatus === 'new')
    .sort((a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate))
    .slice(0, 5);

  const formatDate = (date) => moment(date).format('MMMM Do YYYY');
  const getStatusIcon = (status) => status === 'new' ? <PendingIcon /> : null;
  const getStatusColor = (status) => (status === 'new' ? 'info' : 'default');

  if (status === 'loading') {
    return (
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 3 }}>
        Error loading data: {error}
      </Alert>
    );
  }

  return (
    <Box sx={{ py: 4, px: 3, backgroundColor: 'grey.50', minHeight: '100vh' }}>
      {/* User Greeting */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Welcome to Five Spice Dashboard
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar src={photoURL} alt={displayName} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Hello, {displayName}
            </Typography>
            <Typography variant="body2">{currentTime}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Latest New Invoices Section */}
      {latestNewInvoices.length > 0 && (
  <Box sx={{ mb: 4 }}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'text.secondary', mb: 2 }}>
      Latest New Invoices
    </Typography>
    {latestNewInvoices.map((invoice) => (
      <Paper
        key={invoice.doc_id}
        elevation={1}
        sx={{ margin: '10px 0', padding: '15px', borderRadius: '6px', cursor: 'pointer' }}
        onClick={() => navigate(`/dashboard/invoices/${invoice.doc_id}`)}
      >
        <Grid container spacing={1} alignItems="center">
          {/* Invoice Details */}
          <Grid item xs={8}>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                Invoice #{invoice.invoiceNumber} - {invoice.vendorName}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                Date: {formatDate(invoice.invoiceDate)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Total: ${invoice.invoiceTotal || 'N/A'}
              </Typography>
              {invoice.customerName && (
                <Typography variant="body2" color="textSecondary">
                  Customer: {invoice.customerName}
                </Typography>
              )}
              <Link
                href={invoice.documentURL}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
              >
                <Typography variant="body2" color="primary">View PDF</Typography>
              </Link>
            </Box>
          </Grid>

          {/* Status and Action */}
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
              <Chip
                icon={getStatusIcon(invoice.invoiceStatus)}
                label={invoice.invoiceStatus?.toUpperCase()}
                color={getStatusColor(invoice.invoiceStatus)}
                size="small"
                sx={{ fontWeight: 'bold' }}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Divider for better structure */}
        <Divider sx={{ my: 1.5 }} />

        {/* Quick Summary of Items in Invoice */}
        <Box>
          <Typography variant="body2">
            <strong>Total Items:</strong> {invoice.items.length}
          </Typography>
        </Box>
      </Paper>
    ))}
  </Box>
)}


      {/* Metrics Section */}
      <Grid container spacing={3}>
        {[
          { title: 'New Invoices', value: newInvoices, color: 'info' },
          { title: 'Pending Invoices', value: pendingInvoices, color: 'warning' },
          { title: 'Completed Invoices', value: completedInvoices, color: 'success' },
        ].map(({ title, value, color }, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <Paper
              elevation={1}
              sx={{
                p: 3,
                borderRadius: 2,
                textAlign: 'center',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: 'grey.300',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                {title}
              </Typography>
              <Typography variant="h4" sx={{ color: `${color}.main`, mt: 1 }}>
                {value}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
