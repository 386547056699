


import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveInvoice } from "../../../redux/invoiceSlice"
import {
  Paper,
  Box,
  Button,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Skeleton } from '@mui/material';

import SendIcon from "@mui/icons-material/Send";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportIcon from '@mui/icons-material/Report';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import createApi from "../api";
import ProductItem from "./ProductItem";
import SearchProductDialog from "../SearchProductDialog";
import SubmitOverviewDialog from "../SubmitOverviewDialog";
import EnhancedAddItemDialog from "../EnhancedAddItemDialog";  // Add this import
import { Prompt } from 'react-router-dom';

import InvoiceAppBar from "./InvoiceAppBar";
import InvoiceDetailsForm from "./InvoiceDetailsForm";

const ProductsList = ({
  invoiceData,
  currentUser,
  docId,
  onNavigateToMain,
  onInvoiceDeleted,
}) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [vendorName, setVendorName] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [initialSearchTerm, setInitialSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [incompleteItems, setIncompleteItems] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState("new");
  const [isOverviewDialogOpen, setIsOverviewDialogOpen] = useState(false);
  const [isAddItemDialogOpen, setIsAddItemDialogOpen] = useState(false);
  const [currentPackSize, setCurrentPackSize] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [lastSaved, setLastSaved] = useState(null);

  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Add this custom hook after the other state declarations
  const useNavigationPrompt = (hasUnsavedChanges) => {
    useEffect(() => {
      const handleBeforeUnload = (e) => {
        if (hasUnsavedChanges) {
          e.preventDefault();
          e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [hasUnsavedChanges]);
  };


  console.log(invoiceData)

  const api = createApi(currentUser?.stsTokenManager?.accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    if (invoiceData) {
      setItems(invoiceData.standardized.items || []);
      setVendorName(invoiceData.standardized.vendorName || "");
      setInvoiceNumber(invoiceData.standardized.invoiceNumber || "");
      if (invoiceData.standardized.invoiceDate) {
        const date = new Date(invoiceData.standardized.invoiceDate);
        setInvoiceDate(date.toISOString().split("T")[0]);
      }
      setInvoiceStatus(invoiceData.standardized.invoiceStatus || "new");
      setTotalAmount(invoiceData.standardized.invoiceTotal || 0);
    }
  }, [invoiceData]);

  const saveScrollPosition = useCallback(() => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  }, []);

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  const isItemComplete = (item) => {
    return (
      item.description &&
      item.quantity &&
      item.packSize &&
      item.unitPrice &&
      item.squareUp?.itemId
    );
  };
  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
    setHasUnsavedChanges(true);  // Set this to true when any field is changed
  };

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteZeroQty = () => {
    // Implement delete zero quantity items logic
    handleActionsClose();
  };

  const handleReportInvoice = async () => {
    // Implement report invoice logic
  await api.updateInvoiceStatus(docId, "reported");
  navigate("/dashboard/invoices");
    handleActionsClose();
  };

  const handleRefreshData = () => {
    // Implement refresh invoice data logic
    handleActionsClose();
  };

  // Update the handleSaveInvoice function
  const handleSaveInvoice = async () => {
    setIsLoading(true);
    setError(null);
    setSaveStatus('Saving...');

    // Calculate total amount from items
    const calculatedTotal = items.reduce((sum, item) => {
      return sum + (parseFloat(item.unitPrice) * parseInt(item.quantity, 10));
    }, 0);

    const updatedInvoiceData = {
      ...invoiceData,
      actualAmount: calculatedTotal,
      customerName: invoiceData.customerName || "FIVE SPICE GROCERY",
      deliveryDate: invoiceData.deliveryDate || "",
      doc_id: docId,
      documentURL: invoiceData.documentURL,
      invoiceDate,
      invoiceNumber,
      invoiceStatus,
      invoiceTotal: calculatedTotal,
      items: items.map(item => ({
        ...item,
        unitPrice: parseFloat(item.unitPrice) || 0,
        quantity: parseInt(item.quantity, 10) || 0,
        packSize: parseInt(item.packSize, 10) || 1,
        totalPrice: (parseFloat(item.unitPrice) || 0) * (parseInt(item.quantity, 10) || 0)
      })),
      standardized: {
        ...invoiceData.standardized,
        vendorName,
        invoiceDate,
        invoiceNumber,
        invoiceTotal: calculatedTotal,
        items: items.map(item => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice) || 0,
          quantity: parseInt(item.quantity, 10) || 0,
          packSize: parseInt(item.packSize, 10) || 1,
          totalPrice: (parseFloat(item.unitPrice) || 0) * (parseInt(item.quantity, 10) || 0)
        }))
      },
      vendorName,
      lastModified: new Date().toISOString(),
      modifiedBy: currentUser?.displayName || ""
    };

    try {
      await dispatch(saveInvoice(updatedInvoiceData)).unwrap();
      setHasUnsavedChanges(false);
      setLastSaved(new Date().toISOString());
      setSaveStatus('Saved');
      setError("Invoice successfully saved.");
    } catch (err) {
      console.error("Error saving invoice:", err);
      setSaveStatus('Save failed');
      setError("Failed to save invoice. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateItem = useCallback(
    async ({ invoiceId, itemIndex, updatedItemData }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot update items");
        return;
      }

      saveScrollPosition();
      setIsLoading(true);
      setError(null);
      try {
        const result = await api.updateItem(
          invoiceId,
          itemIndex,
          updatedItemData
        );

        await api.updateProductPrice(
          updatedItemData.squareUp.variationId,
          updatedItemData.squareUp.price,
          updatedItemData.squareUp.categoryId
        );

        setItems((prevItems) => {
          const newItems = [...prevItems];
          newItems[itemIndex] = result.updatedItemTableDoc;
          return newItems;
        });
      } catch (err) {
        console.error("Error updating item:", err);
        setError("Failed to update item. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [api, invoiceStatus, saveScrollPosition]
  );

  const handleUpdatePrice = useCallback(
    async (variationId, price) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot update price");
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        await api.updateProductPrice(variationId, price);
        setError("Price successfully updated in Square");
      } catch (err) {
        console.error("Error updating price in Square:", err);
        setError("Failed to update price in Square. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [api, invoiceStatus]
  );

  const handleOpenSearch = useCallback(
    ({ itemIndex, initialSearchTerm, currentPackSize }) => {
      console.log(`handleOpenSearch: Function called with parameters:`, {
        itemIndex,
        initialSearchTerm,
        currentPackSize,
      });

      if (invoiceStatus === "done") {
        console.log("handleOpenSearch: Invoice is done, cannot open search");
        return;
      }

      console.log(
        `handleOpenSearch: Setting selectedItemIndex to ${itemIndex}`
      );
      setSelectedItemIndex(itemIndex);

      console.log(
        `handleOpenSearch: Setting initialSearchTerm to "${initialSearchTerm}"`
      );
      setInitialSearchTerm(initialSearchTerm);

      console.log(
        `handleOpenSearch: Setting currentPackSize to "${currentPackSize}"`
      );
      setCurrentPackSize(currentPackSize);

      console.log("handleOpenSearch: Opening search dialog");
      setIsSearchDialogOpen(true);

      console.log("handleOpenSearch: Function completed");
    },
    [invoiceStatus]
  );

  const handleConfirmSubmitToSquare = async () => {
    setIsLoading(true);
    setError(null);
    const currentDate = new Date().toISOString(); // ISO string format
    try {
      // Prepare the invoice data from the component's state
      const invoiceData = {
        docId,
        vendorName,
        invoiceDate,
        invoiceNumber,
        totalAmount,
        items: items.map((item) => ({
          ...item,
          unitPrice: parseFloat(item.unitPrice),
          quantity: parseInt(item.quantity, 10),
          packSize: parseInt(item.packSize, 10) || 1,
        })),
        submittedBy: currentUser.displayName,
        submittedOn: currentDate
      };

      // Submit the invoice to Square
      await api.submitInvoiceToSquare(invoiceData, currentUser);

      // Update the invoice status
      await api.updateInvoiceStatus(docId, "done");

      setInvoiceStatus("done");
      setError("Invoice successfully submitted to Square and marked as done");
      setIncompleteItems([]);
      setIsOverviewDialogOpen(false);
      navigate("/dashboard/invoices");
    } catch (err) {
      console.error(
        "Error submitting invoice to Square or updating status:",
        err
      );
      setError(
        "Failed to submit invoice to Square or update status. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };


  const handleSubmitToSquare = useCallback(() => {
    if (invoiceStatus === "done") {
      console.log("Invoice is already submitted and marked as done");
      return;
    }

    const incomplete = items.reduce((acc, item, index) => {
      if (!isItemComplete(item)) {
        acc.push(index);
      }
      return acc;
    }, []);

    if (incomplete.length > 0) {
      setIncompleteItems(incomplete);
      setError(
        `Please complete all item details before submitting. Incomplete items: ${incomplete
          .map((i) => i + 1)
          .join(", ")}`
      );
      return;
    }

    setIsOverviewDialogOpen(true);
  }, [items, invoiceStatus]);

  const handleSelectSquareProduct = useCallback(
    (squareProduct) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot select product");
        return;
      }

      saveScrollPosition();
      setItems((prevItems) => {
        const newItems = [...prevItems];
        const selectedItem = newItems[selectedItemIndex];

        const updatedItem = {
          ...selectedItem,
          squareUp: {
            categoryId: squareProduct.categoryId,
            itemId: squareProduct.id,
            itemName: squareProduct.name,
            variationId:
              squareProduct.variations && squareProduct.variations.length > 0
                ? squareProduct.variations[0].id
                : "",
            variationName:
              squareProduct.variations && squareProduct.variations.length > 0
                ? squareProduct.variations[0].name
                : "",
            price:
              squareProduct.variations && squareProduct.variations.length > 0
                ? squareProduct.variations[0].price_money?.amount || 0
                : 0,
            barcode: squareProduct.barcode || squareProduct.sku || "",
          },
          packSize: selectedItem.packSize || currentPackSize, // Preserve the current packSize
        };

        newItems[selectedItemIndex] = updatedItem;
        return newItems;
      });

      setIsSearchDialogOpen(false);
    },
    [selectedItemIndex, invoiceStatus, saveScrollPosition, currentPackSize]
  );



  const handleDeleteItem = useCallback(
    async ({ itemIndex }) => {
      if (invoiceStatus === "done") {
        console.log("Invoice is done, cannot delete item");
        return;
      }
      saveScrollPosition();
      setIsLoading(true);
      setError(null);
      try {
        await api.deleteItem(docId, itemIndex);
        setItems((prevItems) => prevItems.filter((_, i) => i !== itemIndex));
        setIncompleteItems((prevIncomplete) =>
          prevIncomplete
            .filter((i) => i !== itemIndex)
            .map((i) => (i > itemIndex ? i - 1 : i))
        );
        setError("Item successfully deleted");

        // Check if this was the last item
        if (items.length === 1) {
          // If it was the last item, delete the entire invoice
          await api.deleteInvoice(docId, dispatch);
          setError("Last item deleted. Invoice has been removed.");
          // Call onInvoiceDeleted to update parent state
          if (onInvoiceDeleted) {
            onInvoiceDeleted();
          }
        }
      } catch (err) {
        console.error("Error deleting item:", err);
        setError(`Failed to delete item: ${err.message}. Please try again.`);
      } finally {
        setIsLoading(false);
      }
    },
    [
      api,
      docId,
      invoiceStatus,
      saveScrollPosition,
      items.length,
      onNavigateToMain,
      onInvoiceDeleted,
    ]
  );

  const handleDeleteInvoice = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await api.deleteInvoice(docId, dispatch);
      setError("Invoice successfully deleted");
      if (onInvoiceDeleted) {
        onInvoiceDeleted(); // Call the prop to update parent state and navigate
      }
    } catch (err) {
      console.error("Error deleting invoice:", err);
      setError("Failed to delete invoice. Please try again.");
    } finally {
      setIsLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };

  const handleAddItem = useCallback(
    (newItem) => {
      saveScrollPosition();
      // Ensure the item has the correct structure
      const formattedItem = {
        description: newItem.description || '',
        quantity: parseFloat(newItem.quantity) || 0,
        unitPrice: parseFloat(newItem.unitPrice) || 0,
        packSize: newItem.packSize || '',
        totalPrice: (parseFloat(newItem.quantity) * parseFloat(newItem.unitPrice)) || 0,
        squareUp: newItem.squareUp || {
          itemId: '',
          itemName: '',
          variationId: '',
          variationName: '',
          price: 0,
          barcode: ''
        }
      };

      setItems((prevItems) => [...prevItems, formattedItem]);
      setIsAddItemDialogOpen(false);
      setHasUnsavedChanges(true);
    },
    [saveScrollPosition]
  );

  const isInvoiceDone = invoiceStatus === "done";

  return (
    <Paper
      elevation={3}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      <InvoiceAppBar
        saveStatus={saveStatus}
        lastSaved={lastSaved}
        hasUnsavedChanges={hasUnsavedChanges}
        handleSaveInvoice={handleSaveInvoice}
        isLoading={false}
      />


<Box p={2}>
        <InvoiceDetailsForm
          vendorName={vendorName}
          setVendorName={setVendorName}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          isInvoiceDone={isInvoiceDone}
          handleFieldChange={(setter) => (event) => {
            setter(event.target.value);
            setHasUnsavedChanges(true);
          }}
        />
      </Box>

      <Box
        ref={scrollContainerRef}
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          px: 2,
        }}
      >
        {items.map((item, index) => (
          <ProductItem
            key={index}
            item={item}
            invoiceId={docId}
            itemIndex={index}
            onSearch={handleOpenSearch}
            onUpdate={handleUpdateItem}
            onUpdatePrice={handleUpdatePrice}
            onDelete={handleDeleteItem}
            isIncomplete={incompleteItems.includes(index)}
            disabled={isInvoiceDone}
          />
        ))}

      </Box>

      <Divider sx={{ my: 2 }} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 2,
          mt: 2
        }}
      >
        {/* Left side */}
        <Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleSubmitToSquare}
            disabled={isLoading || isInvoiceDone}
            startIcon={<SendIcon />}
          >
            Submit to Square
          </Button>
        </Box>

        {/* Right side */}
        {/* Right side - Replace the existing buttons Box with this */}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleActionsClick}
            startIcon={<MoreVertIcon />}
            disabled={isLoading}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleActionsClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {hasUnsavedChanges && (
              <MenuItem onClick={() => { handleSaveInvoice(); handleActionsClose(); }}>
                <ListItemIcon>
                  <SaveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Save Invoice</ListItemText>
              </MenuItem>
            )}

            <MenuItem onClick={() => { setIsAddItemDialogOpen(true); handleActionsClose(); }}>
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add Item</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => { setIsDeleteDialogOpen(true); handleActionsClose(); }}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDeleteZeroQty}>
              <ListItemIcon>
                <RemoveCircleOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete Zero Qty Items</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleReportInvoice}>
              <ListItemIcon>
                <ReportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report Invoice</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleRefreshData}>
              <ListItemIcon>
                <RefreshIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Refresh Invoice Data</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <SearchProductDialog
        open={isSearchDialogOpen}
        onClose={() => setIsSearchDialogOpen(false)}
        onSelect={handleSelectSquareProduct}
        api={api}
        isLoading={isLoading}
        initialSearchTerm={initialSearchTerm}
      />
      <EnhancedAddItemDialog
        open={isAddItemDialogOpen}
        onClose={() => setIsAddItemDialogOpen(false)}
        onAddItem={handleAddItem}
        onSelect={handleSelectSquareProduct}
        api={api}
        currentUser={currentUser}
        docId={docId}
        initialSearchTerm=""
        isLoading={isLoading}
      />


      <SubmitOverviewDialog
        open={isOverviewDialogOpen}
        onClose={() => setIsOverviewDialogOpen(false)}
        items={items}
        invoiceTotal={totalAmount}
        onConfirm={handleConfirmSubmitToSquare}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this invoice? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteInvoice} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity={
            error && error.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        >
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(ProductsList);

