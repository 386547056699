import React, { useState, useMemo, useCallback } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import {
  Box, Typography, TextField, InputAdornment, FormControl, InputLabel,
  Button, Pagination, MenuItem, Select, CircularProgress, Alert, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import createApi from './api';
import { useAuth } from '../../context/AuthContext';

const AllInvoicesPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser?.stsTokenManager?.accessToken]);

  const { invoices = [] } = useOutletContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('pending');
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('invoiceDate');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Handlers
  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
    setFilterStatus('all'); // Reset to 'all' when searching
  }, []);

  const handleFilterChange = useCallback((event) => {
    setFilterStatus(event.target.value);
  }, []);

  const handleSort = useCallback((field) => {
    setSortField((prevField) => {
      if (prevField === field) {
        setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortDirection('asc');
      }
      return field;
    });
  }, []);

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value);
  }, []);

  // Utility Functions
  const filterInvoices = (invoices) => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    return invoices.filter((invoice) => {
      const statusFilter = {
        pending: ['new', 'pending'].includes(invoice.invoiceStatus),
        new: invoice.invoiceStatus === 'new',
        done: invoice.invoiceStatus === 'done',
        reported: invoice.invoiceStatus === 'reported',
        all: true,
      }[filterStatus] || false;

      const matchesQuery = !lowerCaseQuery || (
        invoice.vendorName?.toLowerCase().includes(lowerCaseQuery) ||
        new Date(invoice.invoiceDate).toLocaleDateString('en-US').includes(lowerCaseQuery)
      );

      return statusFilter && matchesQuery;
    });
  };

  const sortInvoices = (invoices) => {
    return [...invoices].sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
      return sortDirection === 'asc'
        ? new Date(valueA) - new Date(valueB)
        : new Date(valueB) - new Date(valueA);
    });
  };

  const getPaginatedInvoices = (invoices) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return invoices.slice(startIndex, startIndex + itemsPerPage);
  };

  // Computed Values
  const filteredInvoices = useMemo(() => filterInvoices(invoices), [invoices, searchQuery, filterStatus]);
  const sortedInvoices = useMemo(() => sortInvoices(filteredInvoices), [filteredInvoices, sortField, sortDirection]);
  const paginatedInvoices = useMemo(() => getPaginatedInvoices(sortedInvoices), [sortedInvoices, currentPage]);

  if (!invoices) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!Array.isArray(invoices)) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">Failed to load invoices</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', margin: '0 auto' }}>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search by vendor or date"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel>Status</InputLabel>
          <Select value={filterStatus} onChange={handleFilterChange} label="Status">
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="done">Done</MenuItem>
            <MenuItem value="reported">Reported</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { id: 'invoiceDate', label: 'Date' },
                { id: 'invoiceNumber', label: 'Invoice Number' },
                { id: 'vendorName', label: 'Vendor' },
                { id: 'invoiceStatus', label: 'Status' },
                { id: 'invoiceTotal', label: 'Total' },
              ].map(({ id, label }) => (
                <TableCell key={id}>
                  <TableSortLabel
                    active={sortField === id}
                    direction={sortDirection}
                    onClick={() => handleSort(id)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedInvoices.map((invoice) => (
              <TableRow
                key={invoice.doc_id}
                hover
                onClick={() => {
                  api.updateInvoiceStatus(invoice.doc_id, 'pending'); // Update status
                  navigate(`/dashboard/invoices/${invoice.doc_id}`); // Navigate to details
                }}
              >
                <TableCell>{new Date(invoice.invoiceDate).toLocaleDateString()}</TableCell>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.vendorName}</TableCell>
                <TableCell>
                  <Typography variant="caption">{invoice.invoiceStatus}</Typography>
                </TableCell>
                <TableCell>${invoice.invoiceTotal}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={Math.ceil(sortedInvoices.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default AllInvoicesPage;
