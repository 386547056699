import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Paper,
  InputAdornment,
  Alert,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Restore as RestoreIcon,
  Sort as SortIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

const DEFAULT_MARGIN_RANGES = [
  { min: 0, max: 0.33, margin: 65 },     // Micro-price items
  { min: 0.34, max: 0.49, margin: 60 },
  { min: 0.50, max: 0.74, margin: 55 },
  { min: 0.75, max: 0.99, margin: 50 },
  { min: 1.00, max: 1.99, margin: 45 },  // Low-price items
  { min: 2.00, max: 2.99, margin: 40 },
  { min: 3.00, max: 3.99, margin: 35 },
  { min: 4.00, max: 4.99, margin: 33 },
  { min: 5.00, max: 7.49, margin: 30 },  // Mid-price items
  { min: 7.50, max: 9.99, margin: 28 },
  { min: 10.00, max: 12.49, margin: 26 },
  { min: 12.50, max: 14.99, margin: 25 },
  { min: 15.00, max: 19.99, margin: 23 }, // Higher-price items
  { min: 20.00, max: 24.99, margin: 22 },
  { min: 25.00, max: 49.99, margin: 20 },
  { min: 50.00, max: 99.99, margin: 18 },
  { min: 100.00, max: Infinity, margin: 15 } // Very high-value items
];

const UpdateMarginRanges = ({
  open,
  onClose,
  category,
  initialMarginRanges,
  onSave,
  isLoading
}) => {
  const [marginRanges, setMarginRanges] = useState([]);
  const [error, setError] = useState(null);
  const [warnings, setWarnings] = useState([]);

  useEffect(() => {
    setMarginRanges(initialMarginRanges || DEFAULT_MARGIN_RANGES);
  }, [initialMarginRanges]);

  const handleRangeChange = (index, field, value) => {
    const newRanges = [...marginRanges];

    if (field === 'margin') {
      const numValue = parseFloat(value);
      newRanges[index][field] = numValue; // Store as percentage directly (e.g., 25 for 25%)
    } else {
      newRanges[index][field] = value === '' ? '' : parseFloat(value);

      // Sync min/max with adjacent ranges
      if (field === 'max' && index < newRanges.length - 1) {
        newRanges[index + 1].min = parseFloat(value);
      }
      if (field === 'min' && index > 0) {
        newRanges[index - 1].max = parseFloat(value);
      }
    }

    setMarginRanges(newRanges);
  };



  const addNewRange = () => {
    setMarginRanges([
      ...marginRanges,
      { min: marginRanges[marginRanges.length - 1].max || 0, max: '', margin: 0.20 }
    ]);
  };

  const removeRange = (index) => {
    if (marginRanges.length > 1) {
      const newRanges = marginRanges.filter((_, i) => i !== index);
      setMarginRanges(newRanges);
    }
  };

  const validateRanges = () => {
    const errors = [];
    const warnings = [];
    const sortedRanges = [...marginRanges].sort((a, b) => a.min - b.min);
  
    sortedRanges.forEach(({ min, max, margin }, i) => {
      // Check that costs are non-negative
      if (min < 0 || (max !== Infinity && max < 0)) {
        errors.push(`Range ${i + 1}: Costs cannot be negative.`);
      }
  
      // Ensure margin is between 0% and 100% (since we're using whole numbers for margin)
      if (margin < 0 || margin > 100) {
        errors.push(`Range ${i + 1}: Margin must be between 0% and 100%.`);
      }
  
      // Ensure min cost is less than max cost within the same range
      if (min >= max && max !== Infinity) {
        errors.push(`Range ${i + 1}: Min cost must be less than max cost.`);
      }
  
      // Detect gaps between adjacent ranges (except for Infinity in max)
      if (i > 0 && Math.abs(sortedRanges[i - 1].max - min) > 0.01) {
        warnings.push(`Gap detected between ranges ${i} and ${i + 1}: ${sortedRanges[i - 1].max} to ${min}`);
      }
  
      // Ensure adjacent ranges do not overlap
      if (i > 0 && sortedRanges[i - 1].max > min) {
        errors.push(`Range ${i + 1} overlaps with the previous range.`);
      }
    });
  
    setError(errors.length > 0 ? errors.join('\n') : null);
    setWarnings(warnings);
    return errors.length === 0;
  };
  

  const handleSave = async () => {
    if (validateRanges()) {
      // Save margin in decimal format without multiplying by 100
      try {
        await onSave(marginRanges); // No conversion needed here
      } catch (error) {
        setError('Failed to save margin ranges.');
      }
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Update Margin Ranges for {category?.name}</Typography>
        <Typography variant="caption">Configure price-based margin ranges for automatic pricing</Typography>
      </DialogTitle>

      <DialogContent>
        {(error || warnings.length > 0) && (
          <Box mb={3}>
            {error && <Alert severity="error">{error}</Alert>}
            {warnings.length > 0 && <Alert severity="warning">{warnings.map((w, i) => <div key={i}>{w}</div>)}</Alert>}
          </Box>
        )}

        <Grid container spacing={2} mb={2}>
          <Grid item xs={5}><Typography variant="subtitle2">Min Cost ($)</Typography></Grid>
          <Grid item xs={5}><Typography variant="subtitle2">Max Cost ($)</Typography></Grid>
          <Grid item xs={2}><Typography variant="subtitle2">Margin %</Typography></Grid>
        </Grid>

        <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
          {marginRanges.map((range, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2, border: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <TextField
                    label="Min Cost"
                    type="number"
                    value={range.min}
                    onChange={(e) => handleRangeChange(index, 'min', e.target.value)}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Max Cost"
                    type="number"
                    value={range.max === Infinity ? '' : range.max}
                    onChange={(e) => handleRangeChange(index, 'max', e.target.value === '' ? Infinity : e.target.value)}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Margin"
                    type="number"
                    value={range.margin} // Display directly as percentage
                    onChange={(e) => handleRangeChange(index, 'margin', e.target.value)}
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item>
                  <Tooltip title="Remove Range">
                    <IconButton onClick={() => removeRange(index)} color="error" size="small" disabled={marginRanges.length === 1}>
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Box>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={() => setMarginRanges(DEFAULT_MARGIN_RANGES)} startIcon={<RestoreIcon />}>Reset to Defaults</Button>
          <Button onClick={addNewRange} startIcon={<AddIcon />}>Add Range</Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading} startIcon={<SaveIcon />}>
          {isLoading ? <CircularProgress size={20} /> : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMarginRanges;
