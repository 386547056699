import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../../../context/AuthContext';

const AccountInfo = () => {
    const { currentUser } = useAuth();

    return (
        <Box>
            <Typography variant="h6" color="primary" gutterBottom>
                Account Information
            </Typography>
            <Typography>Name: {currentUser.displayName || 'Not set'}</Typography>
            <Typography>Email: {currentUser.email}</Typography>
            <Typography>Last Login: {currentUser.metadata.lastSignInTime}</Typography>
            <Typography>Account Created: {currentUser.metadata.creationTime}</Typography>
        </Box>
    );
};

export default AccountInfo;
