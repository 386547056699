import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../../context/AuthContext';
import createApi from './api';
import LoadingOverlay from '../../LoadingOverlay'; // Import the LoadingOverlay component
import { 
    Box, 
    Container, 
    Typography, 
    Button, 
    TextField, 
    Alert, 
    List, 
    ListItem, 
    ListItemText, 
    Divider, 
    Modal, 
    Paper, 
    IconButton,
    InputAdornment 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';

const Vendors = () => {
    const { currentUser } = useAuth();
    const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser]);

    const [vendors, setVendors] = useState([]);
    const [filteredVendors, setFilteredVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formState, setFormState] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortAscending, setSortAscending] = useState(true);

    // Fetch vendors on component mount
    useEffect(() => {
        const fetchVendors = async () => {
            setLoading(true);
            try {
                const response = await api.getVendors();
                const vendorList = Array.isArray(response.vendors) ? response.vendors : [];
                setVendors(vendorList);
                setFilteredVendors(vendorList);
                setError(null);
            } catch (err) {
                setError('Failed to load vendors.');
            } finally {
                setLoading(false);
            }
        };
        fetchVendors();
    }, [api]);

    // Handle search input
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filteredList = vendors.filter(vendor =>
            vendor.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredVendors(filteredList);
    };

    // Sort vendors alphabetically or by status
    const handleSort = () => {
        const sortedVendors = [...filteredVendors].sort((a, b) => {
            if (a.name < b.name) return sortAscending ? -1 : 1;
            if (a.name > b.name) return sortAscending ? 1 : -1;
            return 0;
        });
        setFilteredVendors(sortedVendors);
        setSortAscending(!sortAscending);
    };

    // Select a vendor and populate form state, open modal
    const handleSelectVendor = (vendor) => {
        setSelectedVendor(vendor);
        setFormState(vendor);
        setIsModalOpen(true);
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Save updated vendor details
    const handleSaveVendor = async () => {
        try {
            setLoading(true);
            const updatedVendor = await api.updateVendor(formState);
            setVendors((prevVendors) =>
                prevVendors.map((v) => (v.id === updatedVendor.id ? updatedVendor : v))
            );
            setFilteredVendors((prevVendors) =>
                prevVendors.map((v) => (v.id === updatedVendor.id ? updatedVendor : v))
            );
            setIsModalOpen(false); // Close modal after update
            setError(null);
        } catch (err) {
            setError('Failed to update vendor.');
        } finally {
            setLoading(false);
        }
    };

    // Render vendor list
    const renderVendorList = () => (
        <List>
            {filteredVendors.map((vendor) => (
                <React.Fragment key={vendor.id}>
                    <ListItem button onClick={() => handleSelectVendor(vendor)}>
                        <ListItemText
                            primary={vendor.name || 'No Name Available'}
                            secondary={`Status: ${vendor.status || 'N/A'}`}
                        />
                    </ListItem>
                    <Divider />
                </React.Fragment>
            ))}
        </List>
    );

    // Render modal for editing vendor details
   // Updated renderVendorModal to include address, contacts, and note fields
const renderVendorModal = () => (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="edit-vendor-modal">
        <Paper sx={{ width: 400, margin: '100px auto', padding: 4 }}>
            <Typography variant="h6" id="edit-vendor-modal">Edit Vendor</Typography>
            
            {/* Vendor Name */}
            <TextField
                fullWidth
                margin="normal"
                label="Vendor Name"
                name="name"
                value={formState.name || ''}
                onChange={handleChange}
            />
            
            {/* Account Number */}
            <TextField
                fullWidth
                margin="normal"
                label="Account Number"
                name="accountNumber"
                value={formState.accountNumber || ''}
                onChange={handleChange}
            />
            
            {/* Address Fields */}
            {formState.address && (
                <>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Address Line 1"
                        name="addressLine1"
                        value={formState.address.addressLine1 || ''}
                        disabled
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Country"
                        name="country"
                        value={formState.address.country || ''}
                        disabled
                    />
                </>
            )}

            {/* Contacts */}
            {formState.contacts && formState.contacts.length > 0 && (
                <>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Contact Information</Typography>
                    {formState.contacts.map((contact, index) => (
                        <Box key={contact.id} sx={{ mt: 1, pl: 1 }}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label={`Contact Name ${index + 1}`}
                                name={`contactName${index}`}
                                value={contact.name || ''}
                                disabled
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Email"
                                name={`contactEmail${index}`}
                                value={contact.emailAddress || ''}
                                disabled
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Phone Number"
                                name={`contactPhone${index}`}
                                value={contact.phoneNumber || ''}
                                disabled
                            />
                        </Box>
                    ))}
                </>
            )}

            {/* Note */}
            <TextField
                fullWidth
                margin="normal"
                label="Note"
                name="note"
                value={formState.note || ''}
                multiline
                onChange={handleChange}
            />

            {/* Status */}
            <TextField
                fullWidth
                margin="normal"
                label="Status"
                name="status"
                value={formState.status || ''}
                disabled
            />

            {/* Save and Cancel Buttons */}
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="contained" color="primary" onClick={handleSaveVendor}>
                    Save
                </Button>
                <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
                    Cancel
                </Button>
            </Box>
        </Paper>
    </Modal>
);


    return (
        <Container maxWidth="md" sx={{ mt: 4, position: 'relative' }}>
            <Typography variant="h4" gutterBottom>
                Vendors
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                <TextField
                    placeholder="Search vendors"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton onClick={handleSort}>
                    <SortIcon />
                </IconButton>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box flex={1} mr={2}>
                    <Typography variant="h6">Vendor List</Typography>
                    {renderVendorList()}
                </Box>
            </Box>
            {renderVendorModal()}
            {loading && <LoadingOverlay />} {/* Display overlay when loading */}
        </Container>
    );
};

export default Vendors;
