import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Typography, Stack, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { getAuth, linkWithPopup, unlink, GoogleAuthProvider } from 'firebase/auth';

const LinkedAccounts = ({ setSuccessMessage, setError }) => {
    const auth = getAuth();
    const [linkedProviders, setLinkedProviders] = useState([]);
    const [isLinkingGoogle, setIsLinkingGoogle] = useState(false);

    useEffect(() => {
        if (auth.currentUser) {
            const providers = auth.currentUser.providerData.map(provider => provider.providerId);
            setLinkedProviders(providers);
        }
    }, [auth.currentUser]);

    const handleLinkGoogle = async () => {
        setIsLinkingGoogle(true);
        const provider = new GoogleAuthProvider();

        try {
            await linkWithPopup(auth.currentUser, provider);
            setSuccessMessage('Google account linked successfully!');
            setLinkedProviders([...linkedProviders, 'google.com']);
        } catch (err) {
            setError(err.message || 'Failed to link Google account.');
        } finally {
            setIsLinkingGoogle(false);
        }
    };

    const handleUnlinkGoogle = async () => {
        try {
            await unlink(auth.currentUser, 'google.com');
            setSuccessMessage('Google account unlinked successfully!');
            setLinkedProviders(linkedProviders.filter(p => p !== 'google.com'));
        } catch (err) {
            setError(err.message || 'Failed to unlink Google account.');
        }
    };

    return (
        <Box>
            <Typography variant="h6" color="primary" gutterBottom>
                Linked Accounts
            </Typography>
            <Stack spacing={2}>
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={linkedProviders.includes('google.com') ? handleUnlinkGoogle : handleLinkGoogle}
                    disabled={isLinkingGoogle}
                    fullWidth
                >
                    {isLinkingGoogle ? <CircularProgress size={24} /> : linkedProviders.includes('google.com') ? 'Unlink Google Account' : 'Link Google Account'}
                </Button>
                
            </Stack>
        </Box>
    );
};

export default LinkedAccounts;
