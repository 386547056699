import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Paper,
  Grid,
  Divider,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  updateDoc,
  getDoc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { useNavigate, useOutletContext } from 'react-router-dom';
import packageJson from '../../../../package.json'; // Adjust the path to package.json


const firestore = getFirestore();
const auth = getAuth();

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const Settings = () => {
  const navigate = useNavigate();
  const { currentUser } = useOutletContext();

  // States
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  const [settings, setSettings] = useState({
    defaultMailbox: '',
    autoProcessInvoices: true,
    notifyOnNewInvoice: true,
    squareApiKey: '',
    emailNotifications: '',
    defaultLocation: '',
    defaultVendor: '',
    invoicePrefix: '',
    enableAutoMapping: true,
    enableNotifications: true
  });

  const [company, setCompany] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [companyMembers, setCompanyMembers] = useState([]);

  // Load user data and company association
  useEffect(() => {
    const loadData = async () => {
      if (!currentUser?.uid) {
        setError('No authenticated user found');
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.companyId) {
            const companyDoc = await getDoc(doc(firestore, 'companies', userData.companyId));
            if (companyDoc.exists()) {
              const companyData = { ...companyDoc.data(), id: userData.companyId };
              setCompany(companyData);
              setIsAdmin(userData.isAdmin);
              setSettings(prevSettings => ({
                ...prevSettings,
                ...companyData.settings
              }));
              await loadCompanyMembers(userData.companyId);
            }
          }
        }
      } catch (err) {
        setError(`Error loading settings: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [currentUser]);

  // Load company members
  const loadCompanyMembers = async (companyId) => {
    try {
      const membersQuery = query(
        collection(firestore, 'users'),
        where('companyId', '==', companyId)
      );
      const membersSnapshot = await getDocs(membersQuery);
      const members = membersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCompanyMembers(members);
    } catch (err) {
      console.error('Error loading company members:', err);
    }
  };

  // Handle form changes
  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: event.target.type === 'checkbox' ? checked : value
    }));
  };

  // Create new company
  const handleCreateCompany = async () => {
    if (!newCompanyName.trim()) {
      setError('Company name is required');
      return;
    }

    setSaving(true);
    try {
      const companyRef = await addDoc(collection(firestore, 'companies'), {
        companyName: newCompanyName,
        adminUserId: currentUser.uid,
        members: [currentUser.uid],
        settings: settings,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      await setDoc(doc(firestore, 'users', currentUser.uid), {
        companyId: companyRef.id,
        isAdmin: true,
        updatedAt: new Date()
      }, { merge: true });

      setCompany({
        id: companyRef.id,
        companyName: newCompanyName,
        adminUserId: currentUser.uid,
        members: [currentUser.uid]
      });
      setIsAdmin(true);
      setNewCompanyName('');
      setSuccess('Company created successfully');
    } catch (error) {
      setError(`Error creating company: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  // Send invitation
  const handleSendInvitation = async () => {
    if (!inviteEmail.trim()) {
      setError('Email is required');
      return;
    }

    setSaving(true);
    try {
      await addDoc(collection(firestore, 'invitations'), {
        email: inviteEmail,
        companyId: company.id,
        status: 'pending',
        createdAt: new Date(),
        invitedBy: currentUser.uid
      });

      const actionCodeSettings = {
        url: `${window.location.origin}/join-company?companyId=${company.id}`,
        handleCodeInApp: true
      };

      await sendSignInLinkToEmail(auth, inviteEmail, actionCodeSettings);
      setSuccess('Invitation sent successfully');
      setInviteDialogOpen(false);
      setInviteEmail('');
    } catch (error) {
      setError(`Error sending invitation: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  // Save settings
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!company) return;

    setSaving(true);
    try {
      await updateDoc(doc(firestore, 'companies', company.id), {
        settings: settings,
        updatedAt: new Date()
      });
      setSuccess('Settings saved successfully');
    } catch (error) {
      setError(`Error saving settings: ${error.message}`);
    } finally {
      setSaving(false);
    }
  };

  const handleRemoveMember = (memberId) => {
    setMemberToDelete(memberId);
    setDeleteDialogOpen(true);
  };

  const confirmRemoveMember = async () => {
    if (!memberToDelete) return;

    setSaving(true);
    try {
      await updateDoc(doc(firestore, 'users', memberToDelete), {
        companyId: null,
        isAdmin: false
      });
      await loadCompanyMembers(company.id);
      setSuccess('Member removed successfully');
    } catch (error) {
      setError(`Error removing member: ${error.message}`);
    } finally {
      setSaving(false);
      setDeleteDialogOpen(false);
      setMemberToDelete(null);
    }
  };
  console.log(packageJson.version);


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="General Settings" />
            <Tab label="Company" />
            <Tab label="Integrations" />
            {isAdmin && <Tab label="Team Management" />}
            <Tab label="About" /> {/* Add new tab */}
          </Tabs>
        </Box>

        {/* Notifications */}
        <Snackbar
          open={!!error || !!success}
          autoHideDuration={6000}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
        >
          <Alert severity={error ? "error" : "success"}
            onClose={() => {
              setError(null);
              setSuccess(null);
            }}
          >
            {error || success}
          </Alert>
        </Snackbar>

        <TabPanel value={activeTab} index={3}>
  <Box sx={{ spacing: 2 }}>
    <Typography variant="h6" gutterBottom>
      About the App
    </Typography>
    <Box sx={{ mb: 2 }}>
      <Typography variant="body1" gutterBottom>
        Version: <strong>{packageJson.version}</strong>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Build Date: <strong>{process.env.REACT_APP_BUILD_DATE || 'Unknown'}</strong>
      </Typography>
    </Box>
    <Typography variant="body2" color="textSecondary">
      This app was built to help manage your company's workflows efficiently.
    </Typography>
  </Box>
</TabPanel>

        {/* General Settings Tab */}
        <TabPanel value={activeTab} index={0}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Default Mailbox"
                  name="defaultMailbox"
                  value={settings.defaultMailbox}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Invoice Prefix"
                  name="invoicePrefix"
                  value={settings.invoicePrefix}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.autoProcessInvoices}
                      onChange={handleChange}
                      name="autoProcessInvoices"
                    />
                  }
                  label="Automatically process new invoices"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.enableAutoMapping}
                      onChange={handleChange}
                      name="enableAutoMapping"
                    />
                  }
                  label="Enable automatic item mapping"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.enableNotifications}
                      onChange={handleChange}
                      name="enableNotifications"
                    />
                  }
                  label="Enable email notifications"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
                startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
              >
                Save Changes
              </Button>
            </Box>
          </form>
        </TabPanel>

        {/* Company Tab */}
        <TabPanel value={activeTab} index={1}>
          {company ? (
            <Box>
              <Typography variant="h6" gutterBottom>
                Company: {company.companyName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Member since: {new Date(company.createdAt?.toDate()).toLocaleDateString()}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" gutterBottom>
                Create a Company
              </Typography>
              <TextField
                fullWidth
                label="Company Name"
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                onClick={handleCreateCompany}
                disabled={saving || !newCompanyName.trim()}
                startIcon={saving ? <CircularProgress size={20} /> : <AddIcon />}
              >
                Create Company
              </Button>
            </Box>
          )}
        </TabPanel>

        {/* Integrations Tab */}
        <TabPanel value={activeTab} index={2}>
          <Grid container spacing={3}>
            {isAdmin && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Square API Key"
                  name="squareApiKey"
                  value={settings.squareApiKey}
                  onChange={handleChange}
                  type="password"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email for notifications"
                name="emailNotifications"
                value={settings.emailNotifications}
                onChange={handleChange}
                type="email"
              />
            </Grid>
          </Grid>
        </TabPanel>

        
        

      </Paper>

      {/* Invite Dialog */}
      <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)}>
        <DialogTitle>Invite Team Member</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSendInvitation}
            disabled={saving || !inviteEmail.trim()}
            variant="contained"
          >
            Send Invitation
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Settings;