import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Divider,
  Typography,
  Box,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const EnhancedAddItemDialog = ({
  open,
  onClose,
  onAddItem,
  onSelect,
  api,
  currentUser,
  docId,
  initialSearchTerm = '',
}) => {
  // Tab state
  const [activeTab, setActiveTab] = useState(0);

  // Search state
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  // Manual entry state
  const [manualItem, setManualItem] = useState({
    description: '',
    quantity: 1,
    unitPrice: 0,
    packSize: '',
    barcode: '',
    vendorId: '',
  });

  // Selected Square item state
  const [selectedSquareItem, setSelectedSquareItem] = useState(null);

  const handleSearch = useCallback(async () => {
    setSearchError(null);
    setIsSearching(true);
    try {
      const results = await api.searchSquareProducts(searchTerm);
      setSearchResults(results);
    } catch (err) {
      console.error('Error searching products:', err);
      setSearchError('Failed to search products. Please try again.');
    } finally {
      setIsSearching(false);
    }
  }, [api, searchTerm]);

  const handleSelectSquareItem = (item) => {
    setSelectedSquareItem(item);
    setManualItem({
      ...manualItem,
      description: item.itemName,
      unitPrice: item.price,
      barcode: item.sku || '',
    });
  };

  const handleAddComplete = async () => {
    const newItem = {
      ...manualItem,
      totalPrice: (manualItem.quantity * manualItem.unitPrice) || 0,
      squareUp: selectedSquareItem ? {
        itemId: selectedSquareItem.productId,
        itemName: selectedSquareItem.itemName,
        variationId: selectedSquareItem.variationId,
        variationName: selectedSquareItem.variationName,
        price: selectedSquareItem.price,
        barcode: selectedSquareItem.sku || '',
      } : {
        itemId: '',
        itemName: '',
        variationId: '',
        variationName: '',
        price: 0,
        barcode: '',
      }
    };

    try {
      const addedItem = await api.addItem(docId, newItem);
      onAddItem(addedItem);
      onClose();
    } catch (error) {
      console.error('Error adding item:', error);
      setSearchError('Failed to add item. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add Invoice Item</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          {/* Left side - Square Search */}
          <Grid item xs={12} md={6}>
            <Paper elevation={0}>
              <Box p={2}>
                <Typography variant="h6" gutterBottom>Search Square Products</Typography>
                <Box display="flex" gap={1} mb={2}>
                  <TextField
                    fullWidth
                    label="Search Square Products"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  />
                  <IconButton onClick={handleSearch} disabled={isSearching}>
                    {isSearching ? <CircularProgress size={24} /> : <SearchIcon />}
                  </IconButton>
                </Box>
                {searchError && <Alert severity="error" sx={{ mb: 2 }}>{searchError}</Alert>}
                <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
                  {searchResults.map((result) => (
                    <ListItem 
                      button 
                      key={result.productId}
                      selected={selectedSquareItem?.productId === result.productId}
                      onClick={() => handleSelectSquareItem(result)}
                    >
                      <ListItemText
                        primary={result.itemName}
                        secondary={
                          <>
                            <Typography variant="body2">
                              Price: ${result.price.toFixed(2)}
                            </Typography>
                            {result.sku && (
                              <Typography variant="body2">
                                SKU: {result.sku}
                              </Typography>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Paper>
          </Grid>

          {/* Right side - Item Details */}
          <Grid item xs={12} md={6}>
            <Paper elevation={0}>
              <Box p={2}>
                <Typography variant="h6" gutterBottom>Item Details</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      value={manualItem.description}
                      onChange={(e) => setManualItem({...manualItem, description: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      type="number"
                      value={manualItem.quantity}
                      onChange={(e) => setManualItem({...manualItem, quantity: parseFloat(e.target.value)})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Unit Price"
                      type="number"
                      value={manualItem.unitPrice}
                      onChange={(e) => setManualItem({...manualItem, unitPrice: parseFloat(e.target.value)})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Pack Size"
                      value={manualItem.packSize}
                      onChange={(e) => setManualItem({...manualItem, packSize: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Barcode"
                      value={manualItem.barcode}
                      onChange={(e) => setManualItem({...manualItem, barcode: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Vendor ID"
                      value={manualItem.vendorId}
                      onChange={(e) => setManualItem({...manualItem, vendorId: e.target.value})}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddComplete}
          startIcon={<AddIcon />}
        >
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EnhancedAddItemDialog);