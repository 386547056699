import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AppBar, 
  Box, 
  Toolbar, 
  IconButton, 
  Typography, 
  Menu, 
  Container, 
  Avatar, 
  Tooltip, 
  MenuItem, 
  Button 
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { getAuth } from 'firebase/auth';
import logo from '../../assets/FiveSpiceHorizontal.svg';

function ResponsiveAppBar() {
    const { currentUser } = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = async () => {
        handleCloseUserMenu();
        const auth = getAuth();
        await auth.signOut();
        navigate('/');
    };

    const renderUserMenu = () => {
        if (!currentUser) {
            return (
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => navigate('/login')}
                    sx={{ ml: 2 }}
                >
                    Login
                </Button>
            );
        }

        const avatarContent = currentUser.photoURL ? (
            <Avatar alt="User" src={currentUser.photoURL} />
        ) : (
            <Avatar sx={{ bgcolor: 'primary.main' }}>
                {currentUser.displayName?.charAt(0) ?? 'A'}
            </Avatar>
        );

        return (
            <Box sx={{ ml: 2 }}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {avatarContent}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem onClick={() => {
                        navigate('/dashboard');
                        handleCloseUserMenu();
                    }}>
                        <Typography textAlign="center">Dashboard</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        navigate('/dashboard/profile');
                        handleCloseUserMenu();
                    }}>
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        );
    };

    return (
        <AppBar 
            position="static" 
            sx={{ 
                backgroundColor: 'white', 
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Container maxWidth="xl">
                <Toolbar 
                    disableGutters 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        minHeight: '70px' // Adjust this value to match your logo height
                    }}
                >
                    {/* Left side - Logo */}
                    <Box onClick={() => navigate('/')} sx={{ flexGrow: 1, display: 'flex' }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '60px', cursor: 'pointer' }} />
                    </Box>
                    {/* Right side - User Menu */}
                    {renderUserMenu()}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
