import React, { useState, useRef, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential, PhoneAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { 
    Container, TextField, Button, Typography, Box, Alert, 
    InputAdornment, Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle, Tabs, Tab, Paper 
} from '@mui/material';
import { CheckCircleOutline, EmailOutlined, PhoneAndroid } from '@mui/icons-material';
import axios from 'axios';

const Login = () => {
    const [loginMethod, setLoginMethod] = useState('phone'); // 'phone' or 'email'
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [error, setError] = useState('');
    const [isEmployee, setIsEmployee] = useState(false);
    const [openCompleteProfile, setOpenCompleteProfile] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const auth = getAuth();
    const recaptchaVerifierRef = useRef(null);

    useEffect(() => {
        if (currentUser) {
            navigate('/dashboard');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (loginMethod === 'phone') {
            recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
            });
        }
    }, [auth, loginMethod]);

    const handleLoginMethodChange = (_, newValue) => {
        setLoginMethod(newValue);
        setError('');
    };

    const handlePhoneNumberChange = (value) => {
        const formattedNumber = value.startsWith('+') ? value : `+${value}`;
        setPhoneNumber(formattedNumber);
    };

    const handleSendCode = async () => {
        setError('');
        setIsEmployee(false);

        if (phoneNumber.length < 12 || phoneNumber.length > 14) {
            setError('Please enter a valid phone number.');
            return;
        }

        try {
            const response = await axios.get(`https://api.fivespiceindiangrocery.com/api/isEmployee?phoneNumber=${encodeURIComponent(phoneNumber)}`);
            if (response.data.isEmployee) {
                setIsEmployee(true);
                const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifierRef.current);
                setVerificationId(confirmationResult.verificationId);
            } else {
                setError('This phone number is not associated with an employee account.');
            }
        } catch (error) {
            console.error("Error verifying employee status", error);
            setError('Failed to verify employee status. Please try again.');
        }
    };

// In your Login component, modify the handleVerifyCode and handleEmailLogin functions:

// In the Login component, modify the handleVerifyCode function:
const handleVerifyCode = async () => {
    try {
        const credential = PhoneAuthProvider.credential(verificationId, code);
        await signInWithCredential(auth, credential);
        const user = auth.currentUser;
        const idToken = await user.getIdToken();
        console.log(idToken)

        try {
            const response = await axios.get(
                `https://api.fivespiceindiangrocery.com/api/isEmployee?phoneNumber=${encodeURIComponent(phoneNumber)}`,
                { headers: { idToken: `${idToken}` } } // Include the ID token in the request
            );

            if (response.data.isEmployee) {
                setIsEmployee(true);

                if (!response.data.alreadyHasClaim) {
                    // Refresh token to get updated custom claims
                    await user.getIdToken(true); // Force refresh
                }
            } else {
                setError('This phone number is not associated with an employee account.');
                return;
            }
        } catch (error) {
            console.error("Error verifying employee status", error);
            setError('Failed to verify employee status. Please try again.');
            return;
        }

        // Check if user needs KYC - they need it if they don't have both displayName and email provider
        const needsKYC = !user.displayName || !user.providerData.some(
            provider => provider.providerId === 'password'
        );

        if (needsKYC) {
            navigate('/kyc');
        } else {
            navigate('/dashboard');
        }
    } catch (error) {
        console.error("Error during verification", error);
        setError('Verification failed. Please enter the correct code.');
    }
};

const handleEmailLogin = async () => {
    try {
        setError('');
        if (!email || !password) {
            setError('Please enter both email and password');
            return;
        }
        
        await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        
        // Check if user needs KYC - they need it if they don't have both displayName and email provider
        const needsKYC = !user.displayName || !user.providerData.some(
            provider => provider.providerId === 'password'
        );
        
        if (needsKYC) {
            navigate('/kyc');
        } else {
            navigate('/dashboard');
        }

    } catch (error) {
        console.error("Error during email login", error);
        setError('Invalid email or password');
    }
};

    const handleCompleteProfileSave = async () => {
        try {
            const user = auth.currentUser;
            if (name) {
                await user.updateProfile({ displayName: name });
            }
            if (email) {
                await user.updateEmail(email);
            }
            if (password) {
                await user.updatePassword(password);
            }
            setOpenCompleteProfile(false);
            navigate('/dashboard');
        } catch (error) {
            console.error("Error updating profile", error);
            setError('Failed to update profile. Please try again.');
        }
    };

    const handleKeyDown = (event, callback) => {
        if (event.key === 'Enter') {
            callback();
        }
    };

    return (
        <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
                    <Typography component="h1" variant="h5" align="center">
                        Log in
                    </Typography>
                    <Typography align="center" sx={{ mt: 2, mb: 3 }}>
                        Five Spice Indian Grocery Employee Portal
                    </Typography>

                    <Tabs
                        value={loginMethod}
                        onChange={handleLoginMethodChange}
                        centered
                        sx={{ mb: 3 }}
                    >
                        <Tab 
                            icon={<PhoneAndroid />} 
                            label="Phone" 
                            value="phone"
                        />
                        <Tab 
                            icon={<EmailOutlined />} 
                            label="Email" 
                            value="email"
                        />
                    </Tabs>

                    {loginMethod === 'phone' ? (
                        <>
                            <PhoneInput
                                country={'us'}
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%', height: '40px' }}
                                onKeyDown={(event) => handleKeyDown(event, handleSendCode)}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSendCode}
                            >
                                Send Code
                            </Button>
                            {isEmployee && verificationId && (
                                <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Verification Code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CheckCircleOutline />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onKeyDown={(event) => handleKeyDown(event, handleVerifyCode)}
                                    />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={handleVerifyCode}
                                    >
                                        Verify Code
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, handleEmailLogin)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, handleEmailLogin)}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleEmailLogin}
                            >
                                Login
                            </Button>
                        </>
                    )}

                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                    <div id="recaptcha-container"></div>
                </Paper>
            </Container>

            <Dialog open={openCompleteProfile} onClose={() => setOpenCompleteProfile(false)}>
                <DialogTitle>Complete Your Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide your name, email, and a password to complete your profile.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCompleteProfile(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCompleteProfileSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Login;