import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, CircularProgress, Snackbar, Alert, TextField } from '@mui/material';
import createApi from '../api';
import CategoryList from './CategoryList';
import UpdateMarginRanges from './UpdateMarginRanges';
import useDialog from './useDialog';
import useDebouncedValue from './useDebouncedValue';
import Loader from './Loader';
import { formatMarginRanges } from './marginUtils';

const CategoryView = ({ currentUser }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [marginRanges, setMarginRanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 300);
  const { open: dialogOpen, openDialog, closeDialog } = useDialog();
  const api = useMemo(() => createApi(currentUser?.stsTokenManager?.accessToken), [currentUser?.stsTokenManager?.accessToken]);

  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await api.getCategories();
      const formattedData = data.map(category => ({
        ...category,
        marginRanges: formatMarginRanges(category.marginRanges || [])
      }));
      setCategories(formattedData);
      setFilteredCategories(formattedData);
    } catch (error) {
      setError('Failed to fetch categories');
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const filtered = categories.filter(category =>
        category.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories);
    }
  }, [debouncedSearchTerm, categories]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setMarginRanges(category.marginRanges.length > 0 ? category.marginRanges : [{ min: 0, max: '', margin: 0 }]);
    openDialog();
  };

  const handleDialogClose = () => {
    closeDialog();
    setSelectedCategory(null);
    setMarginRanges([]);
  };

  const handleSave = async (updatedRanges) => {
    setIsLoading(true);
    try {
      await api.updateCategoryMarginRange(selectedCategory.squareId, updatedRanges);
      setSuccess('Margin ranges updated successfully');
      handleDialogClose();
      fetchCategories();
    } catch (error) {
      setError('Failed to update margin ranges');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4">Categories</Typography>
      <TextField
        label="Search Categories"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <CategoryList 
          categories={filteredCategories}
          onCategoryClick={handleCategoryClick}
        />
      )}

      <UpdateMarginRanges
        open={dialogOpen}
        onClose={handleDialogClose}
        category={selectedCategory}
        initialMarginRanges={marginRanges}
        onSave={handleSave}
        isLoading={isLoading}
      />

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CategoryView;
