// Default margin ranges with finer increments and gradual reduction for various item types
export const defaultMarginRanges = [
  { min: 0, max: 0.2, margin: 45 },   // Very low-priced items
  { min: 0.2, max: 0.4, margin: 55 },
  { min: 0.4, max: 0.6, margin: 35 },
  { min: 0.6, max: 0.8, margin: 35 },
  { min: 0.8, max: 1, margin: 30 },   // Gradual transition for low-priced items

  // Transition to less extreme margins for higher-priced items
  { min: 1.01, max: 3, margin: 33 },
  { min: 3.01, max: 5, margin: 31 },
  { min: 5.01, max: 9, margin: 26 },
  { min: 9.01, max: 12, margin: 24 },
  { min: 12.01, max: Infinity, margin: 20 }
];

// Validate margin ranges for consistency
const validateMarginRanges = (ranges) => {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    console.warn('Invalid or empty margin ranges. Falling back to default ranges.');
    return false;
  }

  const isValid = ranges.every(range =>
    typeof range.min === 'number' &&
    (typeof range.max === 'number' || range.max === null || range.max === Infinity) &&
    typeof range.margin === 'number'
  );

  return isValid;
};

// Rounding function to ensure prices end in *.x9
const roundToNearestEndingInNine = (value) => {
  const roundedUp = Math.ceil(value * 10) / 10; // Round up to nearest 0.1
  return Math.floor(roundedUp * 10) / 10 + 0.09; // Ensure it ends in .x9
};

const applyRounding = (price) => {
  const roundingTargets = [0.29, 0.49, 0.79, 0.99];
  const fraction = price % 1;
  const basePrice = Math.floor(price);

  if (fraction > 0 && fraction <= 0.14) {
    return basePrice - 1 + 0.99;
  }

  const closestTarget = roundingTargets.reduce((prev, curr) =>
    Math.abs(curr - fraction) < Math.abs(prev - fraction) ? curr : prev
  );
  const targetIndex = roundingTargets.indexOf(closestTarget);
  const nextTarget = roundingTargets[targetIndex + 1];

  if (nextTarget !== undefined) {
    const midpoint = (closestTarget + nextTarget) / 2;
    return fraction <= midpoint ? basePrice + closestTarget : basePrice + nextTarget;
  } else {
    return fraction <= closestTarget ? basePrice + closestTarget : basePrice + 1;
  }
};

// Calculate prices based on margin ranges and apply robust logging
export const calculatePrices = (localItem, categoryData) => {
  console.log('[Price Calculation] Category Data:', categoryData);

  const packSize = parseFloat(localItem.packSize) || 1;
  const unitPrice = parseFloat(localItem.unitPrice) || 0;

  const costPerUnit = unitPrice / packSize;
  console.log('[Price Calculation] Cost per Unit:', costPerUnit);

  // Use category margins if valid and not empty, fallback to default otherwise
  const marginRanges = validateMarginRanges(categoryData?.marginRanges)
    ? categoryData.marginRanges
    : defaultMarginRanges;

  console.log('[Price Calculation] Using Margin Ranges:', marginRanges);

  // Find appropriate margin range
  const marginRange = marginRanges.find(({ min, max }) =>
    costPerUnit >= min && costPerUnit <= (max ?? Infinity)
  ) || { margin: 0 };

  console.log('[Price Calculation] Found Margin Range:', marginRange);

  const margin = marginRange.margin;
  console.log('[Price Calculation] Applied Margin:', margin);

  // Calculate markup price
  const markupPrice = costPerUnit / (1 - margin / 100);
  console.log('[Price Calculation] Markup Price (before rounding):', markupPrice);

  const finalPrice = parseFloat(applyRounding(markupPrice).toFixed(2));
  console.log('[Price Calculation] Final Calculated Price:', finalPrice);

  return finalPrice;
};


// Calculate price difference and log details
export const calculatePriceDifference = (currentPrice, calculatedPrice) => {
  const parsedCurrentPrice = parseFloat(currentPrice) || 0;
  const priceDifference = parsedCurrentPrice > 0
    ? ((calculatedPrice - parsedCurrentPrice) / parsedCurrentPrice) * 100
    : 0;

  console.log('[Price Difference] Current Price:', parsedCurrentPrice);
  console.log('[Price Difference] Calculated Price:', calculatedPrice);
  console.log('[Price Difference] Difference (%):', priceDifference);

  return priceDifference;
};

// Provide recommendations based on price differences with logging
export const getRecommendation = (priceDifference) => {
  console.log('[Recommendation] Price Difference:', priceDifference);

  if (priceDifference > 6) return 'increase';
  if (priceDifference < -6) return 'decrease';
  return 'keep the same';
};
