import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Tooltip,
  IconButton,
  Card,
  CardMedia,
  CardActions,
  Snackbar,
  Alert,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [editableProduct, setEditableProduct] = useState(state?.product || {});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);

  const handleInputChange = (field, value) => {
    setEditableProduct((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    // Simulate save logic
    console.log('Product saved:', editableProduct);
    setSnackbarOpen(true);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Product Details
      </Typography>

      <Card sx={{ mb: 4, padding: 3 }}>
        <Grid container spacing={3}>
          {/* Reusable Input Field */}
          <ProductField
            label="Product Name"
            value={editableProduct.itemName}
            onChange={(value) => handleInputChange('itemName', value)}
          />
          <ProductField
            label="SKU"
            value={editableProduct.sku}
            onChange={(value) => handleInputChange('sku', value)}
          />
          <ProductField
            label="Price"
            value={editableProduct.price}
            onChange={(value) => handleInputChange('price', value)}
            type="number"
          />
          <ProductField
            label="Stock Level"
            value={editableProduct.stockLevel}
            onChange={(value) => handleInputChange('stockLevel', value)}
            type="number"
          />
          <ProductField
            label="Reorder Threshold"
            value={editableProduct.threshold}
            onChange={(value) => handleInputChange('threshold', value)}
            type="number"
          />
          <ProductField
            label="Category"
            value={editableProduct.categoryName || 'N/A'}
            disabled
          />

          {/* Image Display */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Product Images:
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              {(imagePreview.length ? imagePreview : editableProduct.imageUrls || []).map(
                (url, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    height="140"
                    image={url}
                    alt={`Product Image ${index}`}
                    sx={{ width: 'auto', maxWidth: '150px' }}
                  />
                )
              )}
              <Tooltip title="Upload New Image">
                <IconButton component="label">
                  <UploadIcon />
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        <CardActions sx={{ justifyContent: 'space-between', mt: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate(-1)}
          >
            Back to Search
          </Button>
        </CardActions>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Product saved successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

// Reusable ProductField Component
const ProductField = ({ label, value, onChange, type = 'text', disabled = false }) => (
  <Grid item xs={12} md={6}>
    <TextField
      label={label}
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type={type}
      variant="outlined"
      margin="dense"
      disabled={disabled}
    />
  </Grid>
);

export default ProductDetails;
