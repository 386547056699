import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Typography, Grid, Box, TextField, Button, Card, CardContent, InputAdornment, Divider, Snackbar, Alert, Tooltip, Paper, MenuItem, Select, CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { CheckCircleOutline } from '@mui/icons-material';
import createApi from '../api'
import { calculatePrices, calculatePriceDifference, getRecommendation } from './priceCalculator';
import { formatMarginRanges } from '../Views/marginUtils'

const categoryCache = {};

const ProductItem = React.memo(({ item, invoiceId, itemIndex, onSearch, onUpdate, onDelete, disabled, isLoading, currentUser }) => {
  //create API
  const api = createApi(currentUser?.stsTokenManager?.accessToken);

  const [localItem, setLocalItem] = useState(item || {});
  const [categoryData, setCategoryData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [localPrice, setLocalPrice] = useState(item?.squareUp?.price || 0);
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const [categories, setCategories] = useState([]);
  const [IsLoading, setIsLoading] = useState(null)
  const [Error, setError] = useState(null)
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);


  useEffect(() => {
    const fetchCategoriesAndCategoryData = async () => {
      setIsCategoriesLoading(true);

      try {
        // Fetch all categories initially
        const response = await api.getCategories();
        const fetchedCategories = response || [];
        setCategories(fetchedCategories);

        // Populate category cache for quick lookups
        fetchedCategories.forEach((category) => {
          categoryCache[category.squareId] = category;
        });

        // Check if the item has a categoryId and set category data accordingly
        if (localItem?.squareUp?.categoryId) {
          const categoryId = localItem.squareUp.categoryId;

          if (categoryCache[categoryId]) {
            console.log('Loaded category from cache:', categoryCache[categoryId]);
            setCategoryData(categoryCache[categoryId]);
          } else {
            console.warn(`Category ID ${categoryId} not found in fetched categories.`);
            setCategoryData(null);
          }
        } else {
          console.warn('No categoryId found for item:', localItem);
          setCategoryData(null);
        }
      } catch (error) {
        console.error('Error fetching categories or category data:', error.message || error.response);
      } finally {
        setIsCategoriesLoading(false);
      }
    };

    fetchCategoriesAndCategoryData();
  }, [localItem?.squareUp?.categoryId]); // Depend only on categoryId to fetch/update category data


  useEffect(() => {
    setLocalItem(prevLocalItem => ({
      ...item,
      packSize: prevLocalItem.packSize || item.packSize,
    }));
    setLocalPrice(item?.squareUp?.price ? item.squareUp.price : 0);
  }, [item]);

  const handleInputChange = useCallback((field, isSquareUpdate = false) => (event) => {
    const value = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;
    setLocalItem(prev => {
      if (isSquareUpdate) {
        return { ...prev, squareUp: { ...prev.squareUp, [field]: value } };
      }
      return { ...prev, [field]: value };
    });
  }, []);

  const handleSave = useCallback(async () => {
    if (disabled) return;

    const missingFields = [];
    if (!localItem.description) missingFields.push('Description');
    if (localItem.quantity == null) missingFields.push('Quantity');
    if (localItem.unitPrice == null) missingFields.push('Unit Price');
    if (!localItem.packSize) missingFields.push('Pack Size');

    if (missingFields.length > 0) {
      setSnackbarMessage(`Please fill out the following fields: ${missingFields.join(', ')}`);
      setSnackbarOpen(true);
      return;
    }

    try {
      const updatedItemData = {
        ...localItem,
        squareUp: {
          ...localItem.squareUp,
          price: localPrice
        }
      };

      await onUpdate({ invoiceId, itemIndex, updatedItemData });
      setSnackbarMessage('Item updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbarMessage('Failed to update item. Please try again.');
      setSnackbarOpen(true);
    }
  }, [disabled, localItem, localPrice, onUpdate, invoiceId, itemIndex]);

  const handleMatch = useCallback(async () => {
    console.log(`ProductItem ${itemIndex} handleMatch: Starting match process`);
    console.log(`ProductItem ${itemIndex} handleMatch: Current localItem:`, localItem);

    try {
      // Call the onSearch function to get the search result
      const searchResult = await onSearch({
        invoiceId,
        itemIndex,
        initialSearchTerm: localItem.squareUp?.barcode || localItem.description,
        currentPackSize: localItem.packSize
      });

      // Check if a search result was found
      if (searchResult && Object.keys(searchResult).length > 0) {
        console.log(`ProductItem ${itemIndex} handleMatch: Search result found`, searchResult);

        // Update local item with the new data from searchResult
        setLocalItem(prev => ({
          ...prev,
          ...searchResult,
          packSize: prev.packSize || searchResult.packSize
        }));

        // Provide feedback to the user
        setSnackbarMessage('Match found and item updated');
      } else {
        console.log(`ProductItem ${itemIndex} handleMatch: No search result found`);
        setSnackbarMessage('No match found for the item');
      }
    } catch (error) {
      console.error('Error during match:', error);
      setSnackbarMessage('An error occurred while matching the item. Please try again.');
    }

    // Show the snackbar with the result
    setSnackbarOpen(true);
  }, [onSearch, invoiceId, itemIndex, localItem]);

  const formatPrice = useCallback((price) => {
    if (price < 100) {
      return `0.${price.toString().padStart(2, "0")}`;
    } else {
      const dollars = Math.floor(price / 100);
      const cents = price % 100;
      return `${dollars}.${cents.toString().padStart(2, "0")}`;
    }
  }, []);

  const costPerUnit = useMemo(() => {
    return (localItem.packSize && parseFloat(localItem.packSize) > 0)
      ? (localItem.unitPrice || 0) / parseFloat(localItem.packSize)
      : (localItem.unitPrice || 0);
  }, [localItem.packSize, localItem.unitPrice]);

  const costPerPiece = useMemo(() => {
    return localItem.unitPrice / parseFloat(localItem.packSize) || 0;
  }, [localItem.unitPrice, localItem.packSize]);

  const handleTooltipOpen = useCallback(() => {
    const price = calculatePrices(localItem, categoryData);
    setCalculatedPrice(price);
  }, [localItem, categoryData]);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;

    // Log the selected value from the dropdown
    console.log('Selected Category ID:', selectedCategoryId);

    // Log the localItem before update
    console.log('Current localItem:', localItem);

    try {
      await onUpdate({
        invoiceId,
        itemIndex,
        updatedItemData: {
          ...localItem,
          squareUp: {
            ...localItem.squareUp,
            categoryId: selectedCategoryId,
          },
        },
      });

      // Log success
      console.log('Category update successful for itemIndex:', itemIndex);

      setLocalItem((prev) => ({
        ...prev,
        squareUp: {
          ...prev.squareUp,
          categoryId: selectedCategoryId,
        },
      }));

      setSnackbarMessage('Category updated successfully');
    } catch (error) {
      // Log the error details
      console.error('Error updating category:', error.message || error.response);
      setSnackbarMessage('Failed to update category. Please try again.');
    } finally {
      setSnackbarOpen(true);
    }
  };




  const squarePriceInDollars = useMemo(() => localItem.squareUp?.price ? localItem.squareUp.price / 100 : null, [localItem.squareUp?.price]);
  const priceDifference = useMemo(() => calculatePriceDifference(squarePriceInDollars, calculatedPrice), [squarePriceInDollars, calculatedPrice]);
  const recommendation = useMemo(() => getRecommendation(priceDifference), [priceDifference]);

  const hasSquareMatch = useMemo(() => localItem?.squareUp?.itemId && localItem?.squareUp?.variationId, [localItem?.squareUp]);

  const isItemComplete = useMemo(() => localItem.description && localItem.quantity != null &&
    localItem.unitPrice != null && localItem.packSize && hasSquareMatch,
    [localItem, hasSquareMatch]);

  const margin = useMemo(() => ((localPrice / 100 - costPerPiece) / (localPrice / 100)) * 100, [localPrice, costPerPiece]);
  const markup = useMemo(() => ((localPrice / 100 - costPerPiece) / costPerPiece) * 100, [localPrice, costPerPiece]);

  useEffect(() => {
    console.log(`Category data for item ${localItem.description || 'No Description'}:`, categoryData);
  }, [categoryData, localItem]);

  return (
    <Card
      elevation={2}
      sx={{
        mb: 2,
        borderRadius: 2,
        border: '1px solid',
        borderColor: isItemComplete ? 'success.light' : 'warning.light',
        opacity: disabled ? 0.7 : 1,
        maxWidth: '100%',
        transition: 'all 0.2s ease',
        '&:hover': {
          boxShadow: 3,
        }
      }}
    >
      <CardContent sx={{ p: 3 }}>
        {/* Header Section */}
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={8}>
            <Box display="flex" alignItems="center" gap={1}>
              <FiberManualRecordIcon
                sx={{
                  color: isItemComplete ? 'success.main' : 'warning.main',
                  fontSize: 12
                }}
              />
              <Typography variant="h6" fontWeight={500}>
                {localItem.description || 'No Description'}
              </Typography>
            </Box>
            {hasSquareMatch ? (
              <Box mt={1}>
                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <CheckCircleOutline sx={{ fontSize: 16, color: 'success.main' }} />
                  {`${localItem.squareUp?.itemName} - ${localItem.squareUp?.variationName}`}
                </Typography>
                {localItem.squareUp?.barcode && (
                  <Typography variant="caption" color="text.secondary">
                    Barcode: {localItem.squareUp.barcode}
                  </Typography>
                )}
              </Box>
            ) : (
              <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                No Square Item Matched
              </Typography>
            )}

{hasSquareMatch ? (
  <Box sx={{ mt: 2 }}>
    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
      Change Category:
    </Typography>
    <Select
      value={
        categories.some((cat) => cat.squareId === localItem?.squareUp?.categoryId)
          ? localItem?.squareUp?.categoryId
          : ''
      }
      onChange={handleCategoryChange}
      displayEmpty
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 2,
          backgroundColor: '#f9f9f9',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 300,
            '& .MuiMenuItem-root': {
              padding: 1.5,
            },
          },
        },
      }}
    >
      {isCategoriesLoading ? (
        <MenuItem disabled>
          <CircularProgress size={24} sx={{ mr: 2 }} /> Loading categories...
        </MenuItem>
      ) : categories.length > 0 ? (
        categories.map((category) => (
          <MenuItem key={category.squareId} value={category.squareId}>
            {category.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No categories available</MenuItem>
      )}
    </Select>
  </Box>
) : null}

          </Grid>


          {/* Price Summary Box */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                bgcolor: 'grey.50',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'grey.200'
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Total:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ${(localItem.quantity * localItem.unitPrice).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Per Unit:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">${costPerUnit.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">Per Piece:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">${costPerPiece.toFixed(2)}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Input Fields */}
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Quantity"
              type="number"
              value={localItem.quantity || 0}
              onChange={handleInputChange('quantity')}
              disabled={disabled}
              fullWidth
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Pack Size"
              value={localItem.packSize || ''}
              onChange={handleInputChange('packSize')}
              disabled={disabled}
              fullWidth
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Unit Price"
              type="number"
              value={localItem.unitPrice || 0}
              onChange={handleInputChange('unitPrice')}
              disabled={disabled}
              fullWidth
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Tooltip
              title={
                <Box p={1}>
                  <Typography variant="body2">Margin: {margin.toFixed(2)}%</Typography>
                  <Typography variant="body2">Markup: {markup.toFixed(2)}%</Typography>
                  <Typography variant="body2">
                    Calculated: ${calculatedPrice !== null ? calculatedPrice.toFixed(2) : 'Loading...'}
                  </Typography>
                  <typography variant="body2">
                    Category: {categoryData?.name || 'NA'}
                  </typography>
                </Box>
              }
              onOpen={handleTooltipOpen}
            >
              <TextField
                label="Sale Price"
                value={localPrice !== '' ? formatPrice(localPrice) : ''}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setLocalPrice(value ? Number(value) : '');
                }}
                disabled={disabled}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                  }
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={1}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={disabled || isLoading}
            sx={{
              textTransform: 'none',
              px: 3,
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="info"
            startIcon={<SearchIcon />}
            onClick={handleMatch}
            disabled={disabled}
            sx={{
              textTransform: 'none',
              px: 3,
            }}
          >
            Match
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete({ invoiceId, itemIndex })}
            disabled={disabled}
            sx={{
              textTransform: 'none',
              px: 3,
            }}
          >
            Delete
          </Button>
        </Box>
      </CardContent>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes('successfully') ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
});

export default ProductItem;
