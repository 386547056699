import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Divider,
    Toolbar,
    Tooltip
} from '@mui/material';
import {
    ViewList as ViewListIcon,
    FileUpload as FileUploadIcon,
    Settings as SettingsIcon,
    Category as CategoryIcon,
    Search as SearchIcon,
    ListAlt as ListAltIcon,
    LocalOffer as LocalOfferIcon,
    Person as PersonIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Dashboard as DashboardIcon,
    Business as BusinessIcon,
    AttachMoney as AttachMoneyIcon,
    HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';
import InvoiceUploadDialog from './InvoiceUploadDialog';

const ToolbarComponent = ({ isOpen, toggleDrawer, api }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    // Navigation items configuration
    const navigationItems = [
        { title: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
        { title: 'All Invoices', path: '/dashboard/invoices', icon: <ViewListIcon /> },
        { title: 'Categories', path: '/dashboard/categories', icon: <CategoryIcon /> },
        { title: 'User Profile', path: '/dashboard/profile', icon: <PersonIcon /> },
        { title: 'Sales Tool', path: '/dashboard/sales', icon: <LocalOfferIcon /> },
        { title: 'Price Updater', path: '/dashboard/price-updater', icon: <AttachMoneyIcon /> },
        { title: 'Vendors', path: '/dashboard/vendors', icon: <BusinessIcon /> },
        { title: 'How To', path: '/dashboard/how-to', icon: <HelpOutlineIcon /> } // New item
    ];

    // Handle opening and closing the upload dialog
    const handleUploadOpen = () => setUploadOpen(true);
    const handleUploadClose = () => setUploadOpen(false);

    // Handle file drop for invoice upload
    const onDrop = async (acceptedFiles) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await api.uploadInvoice(acceptedFiles[0]);
            setSuccess('Invoice uploaded successfully');
            console.log('Upload response:', response);
            setUploadOpen(false);
            // Navigate to invoices page after successful upload
            navigate('/dashboard/invoices');
        } catch (error) {
            setError('Failed to upload invoice');
            console.error('Upload error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Drawer
                variant="permanent"
                open={isOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        width: isOpen ? 240 : 60,
                        transition: 'width 0.3s',
                        overflowX: 'hidden',
                    },
                }}
            >
                <Toolbar>
                    <IconButton onClick={toggleDrawer}>
                        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    {navigationItems.map((item) => (
                        <Tooltip 
                            key={item.path}
                            title={!isOpen ? item.title : ''} 
                            placement="right" 
                            arrow
                        >
                            <ListItem 
                                button 
                                onClick={() => navigate(item.path)}
                                selected={location.pathname === item.path}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                {isOpen && <ListItemText primary={item.title} />}
                            </ListItem>
                        </Tooltip>
                    ))}

                    {/* Upload Invoice Button - Separate because it opens a dialog */}
                    <Tooltip title={!isOpen ? 'Upload Invoice' : ''} placement="right" arrow>
                        <ListItem button onClick={handleUploadOpen}>
                            <ListItemIcon><FileUploadIcon /></ListItemIcon>
                            {isOpen && <ListItemText primary="Upload Invoice" />}
                        </ListItem>
                    </Tooltip>
                </List>
            </Drawer>

            {/* Dialog for uploading invoices */}
            <InvoiceUploadDialog
                open={uploadOpen}
                onClose={handleUploadClose}
                onDrop={onDrop}
                isLoading={isLoading}
                error={error}
                success={success}
            />
        </>
    );
};

export default ToolbarComponent;